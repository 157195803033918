import React, { PureComponent } from 'react';

class Separator extends PureComponent {
  render() {
    return (
      <section className="bg-white hidden lg:block">
        <div className="container items-center max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="relative">
            <div className="absolute grid grid-cols-7 gap-4 w-40 h-40 -top-20 right-0">
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
              <div className="rounded-full w-2 h-2 bg-yellow-100"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Separator;
